import React from "react"
import Layout from '../../components/layout';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { graphql } from 'gatsby';
import ItemWithMixingRatio from '../../components/itemWithMixingRatio'; 
const epoxyFormulationsPage = ({ data }) => (
	<Layout>
		<Row>
			<Col>
				<h1>Epoxy Formulations for surface coating of Natural Stones</h1>
			</Col>
		</Row>
		{/* Sonam Royal Interior Emulsion */}
		<Row>
			<Col>
				<h2>EPOXY SYSTEMS For Surface Coating of Natural Stones</h2>
			</Col>
		</Row>
        <Row>
            <Col>
                <p>
                The following list of Epoxy Chemicals are suitable for the surface coating of natural stones such as granites, marbles, travertine’s and onyx. They are available as 2 component systems namely, Resin (PART – A) and Hardener (PART – B). Depending on the application and nature of stone, the epoxy chemicals are available in various viscosities and pot life. The curing time of the epoxy chemicals can be altered based on our customer requirements. Our epoxy systems are available in clear, dark, coloured and pigmented versions based on the type, colour and nature of stones. This also makes it suitable for the manufacturing of decorative objects, precious stones and semi-precious stones. Our epoxy systems can be applied using rollers, machines, and metal plates, etc based on the application. The epoxy systems are available in various packing ranging from 1Kg to 200Kgs.
                </p>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>
                Ensure the surface is free of any moisture,grease, or dirt. Mix the Resin (Part A) and Hardener (PartB) in the recommended ratio by weight and apply the mixture uniformly on the surface of the stone. Fill the cracks and pinholes with excess quantity so as to achieve a uniform surface. Allow the slabs to dry and polish them starting from 120-grit abrasives.
                </p>
            </Col>
        </Row>
		<Table striped bordered variant responsive >
            <tbody className="epoxyFormulationsPageTable">
                <tr>
                    <td><b>Product Category</b></td>
                    <td><b>Grade</b></td>
                    <td><b>Ratio</b></td>
                    <td><b>Viscosity</b></td>
                    <td><b>Properties</b></td>
                </tr>
                <tr>
                    <td rowSpan="4">INVISIBLE</td>
                    <td>501</td>
                    <td>4:1</td>
                    <td>Medium</td>
                    <td rowSpan="5">High Reactivity, Good Hardness  </td>
                </tr>
                <tr>
                    <td>901</td>
                    <td>3:1</td>
                    <td>Medium</td>
                </tr>
                <tr>
                    <td>604</td>
                    <td>3:1</td>
                    <td>Medium</td>
                </tr>
                <tr>
                    <td>800</td>
                    <td>3:1</td>
                    <td>Medium</td>
                </tr>
                <tr>
                    <td>TRANSPARENT</td>
                    <td>928</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td rowSpan="7">DARK</td>
                    <td>999</td>
                    <td>3:1</td>
                    <td>Medium</td>
                    <td rowSpan="7">Long hardening time, suggested for dark and colored materials</td>
                </tr>
                <tr>
                    <td>990</td>
                    <td>3:1</td>
                    <td>Medium</td>
                </tr>
                <tr>
                    <td>555</td>
                    <td>3:1 & 4:1</td>
                    <td>Low</td>
                </tr>
                <tr>
                    <td>666 & 777</td>
                    <td>3:1</td>
                    <td>Medium</td>
                </tr>
                <tr>
                    <td>880</td>
                    <td>-</td>
                    <td>Medium</td>
                </tr>
                <tr>
                    <td>444</td>
                    <td>3:1</td>
                    <td>Medium</td>
                </tr>
                <tr>
                    <td>111</td>
                    <td>3:1</td>
                    <td>Medium</td>
                </tr>
                <tr>
                    <td><b>Product Category</b></td>
                    <td><b>Grade</b></td>
                    <td><b>Ratio</b></td>
                    <td><b>Viscosity</b></td>
                    <td><b>Properties</b></td>
                </tr>
                <tr>
                    <td>JET POWER - BLACK</td>
                    <td>460</td>
                    <td>3:2</td>
                    <td rowSpan="11">Very Low</td>
                    <td>Suggested for black materials</td>
                </tr>
                <tr>
                    <td>JET POWER - BLACK</td>
                    <td>820</td>
                    <td>3:2</td>
                    <td>For higher darkness</td>
                </tr>
                <tr>
                    <td>JET POWER - BROWN</td>
                    <td>435</td>
                    <td>3:2</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>JET POWER - CHERRY BROWN</td>
                    <td>-</td>
                    <td>3:2</td>
                    <td>Very low viscosity</td>
                </tr>
                <tr>
                    <td>JET POWER - TAN BROWN</td>
                    <td>-</td>
                    <td>3:2</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>JET POWER - BROWN</td>
                    <td>455</td>
                    <td>3:1:1</td>
                    <td>To add improve the gloss after polishing</td>
                </tr>
                <tr>
                    <td>JET POWER - BLACK</td>
                    <td>451</td>
                    <td>3:1</td>
                    <td></td>
                </tr>
                <tr>
                    <td>JET POWER - RED</td>
                    <td>251</td>
                    <td>3:1</td>
                    <td></td>
                </tr>
                <tr>
                    <td>PREMIUM - BLACK</td>
                    <td>007</td>
                    <td>3:1</td>
                    <td></td>
                </tr>
                <tr>
                    <td>JET POWER - COFFEE BROWN</td>
                    <td>-</td>
                    <td>3:2</td>
                    <td></td>
                </tr>
                <tr>
                    <td>JET POWER - BLUE</td>
                    <td>-</td>
                    <td>3:2</td>
                    <td></td>
                </tr>
                <tr>
                    <td>PREMIUM - RED</td>
                    <td>3000</td>
                    <td>2:1</td>
                    <td>-</td>
                    <td>Low viscosity</td>
                </tr>
                <tr>
                    <td>PREMIUM - RED</td>
                    <td>3040</td>
                    <td>2:1</td>
                    <td rowSpan="7">Medium</td>
                    <td rowSpan="6">Suggested for colored materials</td>
                </tr>
                <tr>
                    <td>PREMIUM - RED</td>
                    <td>001</td>
                    <td>2:1</td>
                </tr>
                <tr>
                    <td>PREMIUM - RED</td>
                    <td>355</td>
                    <td>2:1</td>
                </tr>
                <tr>
                    <td>PREMIUM - BLUE</td>
                    <td>-</td>
                    <td>2:1</td>
                </tr>
                <tr>
                    <td>PREMIUM - YELLOW</td>
                    <td>-</td>
                    <td>2:1</td>
                </tr>
                <tr>
                    <td>PREMIUM - GOLDEN YELLOW</td>
                    <td></td>
                    <td>2:1</td>
                </tr>
                <tr>
                    <td>PREMIUM - BLACK</td>
                    <td>1207</td>
                    <td>3:1:1</td>
                    <td>Increase darkness</td>
                </tr>
            </tbody>
	    </Table> 
        <br />
        <Row>
            <Col>
                <p>
                    The following lists of colours are epoxy functional. They can be mixed with the epoxy systems as per the requirements to achieve the desired colours. They are available in packing ranging from 1Kg to 20Kgs.
                </p>
            </Col>
        </Row>
        <br />
        <Table striped bordered variant responsive>
            <tbody className="epoxyFormulationsPageTable">
                <tr> 
                    <td style={{textAlign:'center'}} colSpan="3"><b>COLOUR</b></td>
                </tr>
                <tr>
                    <td><b>Product</b></td>
                    <td><b>Grade</b></td>
                    <td><b>Properties</b></td>
                </tr>
                <tr>
                    <td>BLACK</td>
                    <td>460</td>
                    <td></td>
                </tr>
                <tr>
                    <td>BLACK</td>
                    <td>820</td>
                    <td>More viscous and darker than 460</td>
                </tr>
                <tr>
                    <td>BROWN</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>TAN BROWN</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>ORANGE</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>DEEP RED</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>DEEP RED</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>BLUE COLOUR</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>YELLOW, GOLDEN YELLOW, WHITE, RED 355(BLOOD RED), etc.</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>AGER - Available in various colours</td>
                    <td></td>
                    <td>To improve shine and gloss</td>
                </tr>
                <tr>
                    <td>RUST REMOVER</td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </Table>
        <br />
        <br />

        <ItemWithMixingRatio  heading={'Stony'} data={'Threpox Stony is a 2 component modified epoxy system which is odorless and solvent free. Its low viscosity allows for easy penetration into cracks. Stony can be used for coating of marbles, granites etc to provide a glossy clear coat which covers micro or hairline cracks and pinholes. This strengthens the surface to make it chemical and water resistant while retaining the natural properties of the stone. Stony can also be used for casting of stones.'} package={'20 ltr, 10 ltr,4ltr,1ltr'} color={'Water white'} ratio={'4 parts of base & 1 part of hardener.'} dryingTime={'Stony is a 2 component modified epoxy system which is odorless an solvent free.Its low viscocity allows fr easy penetration into cracks.Stony can be ued for coating of marbles, granites etc to provide a glossy clear coat which covers micro or hairline cracks and pinholes. This strengthens the surface to make it chemical and water resistant while retaining the natural properties of the stone. Stony can also be used for casting of stones.'} image={data.stony.childImageSharp.fluid}/>

        <hr />
        
 
        <ItemWithMixingRatio  heading={'Ipocrete'} data = {'Threpox Ipocrete is an innovative Epoxy Waterproofinng system with 2 components namely, Resin (Part A) and Hardener (Part B). When the Resin and Hardener are mixed in the ratio 2:1, excellent bond strength for civil applications is achieved. For the best performance, curing at a higher temperature is advised.'} package={'20 ltr, 10 ltr,4ltr,1ltr'} color={'Water white'} ratio={'4 parts of base & 1 part of hardener.'} dryingTime={'Light resin is having excellent glossy finish, crack filling and colour darkening on materials like light granite. Main Products are invisible 501,transperent 928 etc.'} image = {data.ipoCrete.childImageSharp.fluid} />
        <hr />
    </Layout>
)

export default epoxyFormulationsPage;

export const query = graphql`
query {
    ipoCrete: file(relativePath: { eq: "ipoCrete.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    stony: file(relativePath: { eq: "stony.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 5000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    
  }
`
